@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
/* Reset */
*,*::before,*::after {
  box-sizing: border-box;
}
body {
  animation: scrollBackground 800s linear infinite;
  background-blend-mode: overlay;
  background-image: 
  linear-gradient(to bottom, #ff7eb3, #ff5599),
  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23FFFFFF' fill-opacity='0.2'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: repeat;
  font-family: 'Zen Maru Gothic', sans-serif;
  position: relative;
}
@keyframes scrollBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% -100%;
  }
}

h1, h2, h3, h4, h5, h6, p{
  margin: 0;
}
ul, ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.en {font-family: 'Lexend',sans-serif;}
/* Utility */
.mb-2 {
  margin-bottom: 1rem;
}
.mb-3 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 3rem;
}
#root {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
/* Header */
.app-nav {
  position: relative;
}
.branding {
  color: #fff;
  font-size: .7rem;
  padding: 2rem;
}
/* Navigation */
.nav-menu {
  font-size: 2.5rem;
  position: absolute;
  top: 30px;
  right: 2%;
  z-index: 10001;
}
.nav-global.open+.nav-menu {
  border: 1px solid #ff758c;
  border-radius: 50%;
  color: #ff758c;
  height: 48px;
  width: 48px;
}
.nav-global {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, .95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: right 0.3s ease;
  z-index: 10000;
}

.nav-global.open {
  right: 0;
}

.nav-global a, .nav-global a:active, .nav-global a:visited { 
  color: #ff758c;
  display: block;
  font-size: 1.5rem;margin: 10px 0;
  font-size: 1.2rem;
  overflow: hidden;
  padding: .5rem 1rem;
  position: relative;
  text-decoration: none;
  transition: all .3s;
}

.nav-global a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, .5);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  z-index: -1;
}

.nav-global a:hover::before {
  animation: rotateLink 2s linear infinite;
}

@keyframes rotateLink {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}
.btn-menu {
  align-items: center;
  aspect-ratio: 1 / 1;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  height: 60px;
  outline: none;
  width:60px;
}
.btn-apply {
  display: none;
}
/* Hero */
.content {
  background: #fff;
  border-radius: 30px 30px 0 0;
  color: #ff758c;
  width: 96%;
  max-width: 480px;
  margin: 0 auto;
  z-index: 2;
}
.hero {
  position: relative;
  width: 100%;
  height:auto;
  min-height:30vh;
}
.animatedSquare {
  background: linear-gradient(45deg,#ff758c, #ffadad);
  position: absolute;
  width: 20px;
  height: 20px;
  --randX: ${Math.random() * 400 - 200}px;
  --randY: ${Math.random() * 400 - 100}px;
  z-index: 10;
}
@keyframes moveRandomly {
  0%, 100% {
    transform: translate(var(--randXStart), var(--randYStart));
  }
  50% {
    transform: translate(var(--randXEnd), var(--randYEnd));
  }
}
.squares-container {
  opacity: .2;
  position: absolute;
  width: 200%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;  /* ユーザーインタラクションを阻害しないようにする */
}
.hero-copy {
  font-family: 'Lexend', sans-serif;
  position: relative;
}
.main-copy {
  color: #ff758c;
  display: block;
  font-size: 4.5rem;
  font-weight: 700;
  padding: 8px 4px;
  margin: 0;
}
.main-copy-2 {
  font-size: 2rem;
}
.sub-copy {
  font-size: 1rem;
  font-weight: 400;
  padding: 8px 4px;
}
.hero-img img {
  max-width: 100%;
}
.container {
  padding: 6rem 2rem;
}
/* Section */
.section-title {
  margin-bottom: 3rem;
}
.sec-ttl {
  position: relative;
}
.ttl-ja {
  align-items: center;
  font-size: 1.8rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.ttl-sub {
  color: #ff758cb6;
  font-family: 'Lexend', sans-serif;
}
.ttl-sub::before,.ttl-sub::after {
  color: #ff758cb6;
  content: "・・・";
}
/** Features */
.features-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.features-list ul li {
  flex: 1 1 50%;
}
.feature-icn {
  background-color: #fff;
  border-radius: 50%;
  border: .5rem solid #ff758c52;
  padding: 1rem;
}
.features-list li p {
  font-size: .9rem;
  text-align: left;
}
.features01,.features02,.features03 {
  display: flex;
  gap: 2rem;
}
.features02 {
  justify-content: center;
  position: relative;
}
.features02 img {
  border: 1.5rem solid #ff758c52;
  border-radius: 100%;
  box-shadow: 0 0 0 1rem #ff758c18; 
  position: relative;
  max-width: 100%;
}
/** Testimonials */
.testimonials {
  background-attachment: fixed;
  background-image: url('images/bg_testimonials.jpg');
  background-position:  center right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4rem 0;
  position: relative;
}

.testimonials-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.testimonial {
  background:white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.testimonial-comment {
  color: #777;
  font-style: italic;
  margin-bottom: 1rem;
}

.testimonial-author {
  font-weight: bold;
}
/* Splideのページネーションのカスタマイズ */
.splide__pagination__page {
  background: #ff758c;
}

.splide__pagination__page.is-active {
  background: #ff5999;
}
/** How to */
.sec-howto {
  background-color: #ffd5dc;
}
.howto {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}
.howto-item h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.howto-item h3::before {
  content: '0' attr(data-number) ' /';
  display: inline-block;
  font-weight: 700;
  margin-right: 1rem;
  text-align: center;

}
.howto-img {
  border-radius: 1rem;
  max-width: 100%;
}
.howto-item p {
  color: #777;
  margin-bottom: 3rem;
}
/** Pricing */
.pricing-list {
  border: 5px solid #ff758c;
  border-radius: 2rem;
  margin-top: 3rem;
  padding: 1rem;
}
.notion {
  font-size: .8rem;
  font-weight: 300;
}
.price-month {
  border-bottom: 3px dotted #ff758c;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  position: relative;
}
.price-month .price-meta {
  font-size: 1rem; 
  font-weight: 400;
  margin-left: .5rem;
}
.price-meta {display: inline-block;}
.price-initial {
  font-size: .9rem;
}
.note {
  background-color: #f3f3f3;
  border-radius: 1rem;
  color: #777;
  font-size: .8rem;
  margin: 3rem auto 0;
  padding: 1rem;
  text-align: left;
  max-width: 90%;
}
.note-txt {
  margin-bottom: 1rem;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(255,255,255,0.1) 1%, rgba(0,0,0,0) 1%);
  background-size: 3px 3px;
  animation: moveDots 10s linear infinite;
  pointer-events: none;
  z-index: 0;
}

@keyframes moveDots {
  0% {
      background-position: 0 0;
  }
  100% {
      background-position: 100% -100%;
  }
}
/** FAQ */
.faq {
  background-color: #ffd5dc;
}
.faq-list {text-align: left;}
.faq-list dt {
  background-color: #fff;
  border-radius: .5rem;
  padding: 1.5rem 1rem;
  line-height: 1.8;
  margin-top: .5rem;
}
.faq-list dt::before {
  background: #ff758c;
  border-radius: 50%;
  color: white;
  content: 'Q';
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  margin-right: 1rem;
  padding: 0;
  height: 36px;
  width: 36px;
  text-align: center;
  vertical-align: middle;
}
.faq-list dd {
  background-color: #fff;
  height: 0; 
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  transition: all .3s ease-in-out; /* アニメーションの追加 */
}
.faq-question {
  cursor: pointer;
}
.faq-list .faq-question.active {
  border-bottom: 1px solid #eee;
  border-radius: .5rem .5rem 0 0;
}
.faq-list .faq-answer.open {
  border-radius: 0 0 .5rem .5rem;
  opacity: 1;
  height: auto;
  padding: 1.5rem 1rem;
}
/** Contact */
.contact-form {
  padding: 20px;
  margin-top: 20px;
}

.form-group {
  margin-bottom: 10px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 3px solid #ccc;
}
.form-group input[type=checkbox] {
  width: 1rem;
}
.link-uline,.link-uline:hover, .link-uline:visited {
  color: #777;
  font-size: .8rem;
}
.privacy-policy-check {
  align-items: center;
  display: flex;
  justify-content: center;
}
.privacy-policy-check label {
  display: inline;
  font-size: 0.8rem;
}
.btn-submit {
  background-color: #ff758c; /* マテリアルデザインの主色 */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 2rem;
  cursor: pointer;
  font-size: 1.2rem;
  letter-spacing: .25rem;
  transition: background-color 0.3s;
  width: 80%;
}

.submit-button:hover {
  background-color: #ff758c18; /* ホバー時の色変更 */
}
/* Footer */
.site-footer {
  background: #ff758c;
  color: white;
  text-align: center;
  padding: 2rem 0;
}
.footer-branding {
  margin-bottom: 3rem;
}
.footer-nav {
  display: grid;
  gap: 1.5rem;
  grid-template-rows: repeat(3,1fr);
  margin-bottom: 3rem;
}
.link-footer, .link-footer:hover, .link-footer:visited {
  color: white;
  font-size: .8rem;
}
/* Trial */
.trial-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10010;
}

.trial-content {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
}

.trial-content h2 {
  color: #ff758c;
  margin-bottom: 1rem;
}

.trial-content h3 {
  color: #4a4a4a;
  margin-bottom: 1.5rem;
}

.trial-content h4 {
  color: #ff758c;
  margin-bottom: 1rem;
}

.trial-content p {
  margin-bottom: 0.5rem;
}

.trial-content button {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #ff758c;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.trial-content button:hover {
  background-color: #ff5999;
}
/* Desktop */
@media screen and (min-width: 880px) {
  .nav-global {
    align-items: flex-start;
    background-color: transparent;
    height: auto;
    justify-content: flex-start;
    position: fixed;
    top: 50vh;
    left: 2rem;
    transform: translate3d(0, -50%, 0);
    z-index: -1;
  }
  .nav-global a {
    font-size: 1.5rem;
    margin: 0;
    color: white;
  }
  .btn-menu {
    display: none;
  }
  .btn-apply {
    display: inline-block;
    background-color: #ffffff;
    color: #ff6f61;
    border: none;
    padding: 10px 20px;
    border-radius: 50px; /* ピル型ボタン */
    cursor: pointer;
    font-size: 1rem;
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
  .hero {
      min-height: 30vh;
  }
}